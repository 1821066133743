var zipAutofillInit = {
    'key':'AIzaSyAu_devqgFfFDvpYkTJimHzJVHiwEHjmYA',
    'selectors': '#frontpage_form',
    'country':'US',
    'citySelectAttr' : {
        'required': true
    },
    'cityInputAttr': {
        'type':'text',
        'required': true
    }
}

// Footer Pop Out
function openPopUpUrl(e,t,a){return window.open(e,"","height="+t+", width="+a+", scrollbars=yes, resizable=yes");}

// Today's Date
(function() {
    var months = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
        'October', 'November', 'December');
    var days = new Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday');
    var currentTime = new Date();
    var month = currentTime.getMonth();
    var date = currentTime.getDay();
    var day = currentTime.getDate();
    var year = currentTime.getFullYear();
    var hours = currentTime.getHours();
    var minutes = currentTime.getMinutes();
    var seconds = currentTime.getSeconds();
    document.getElementById('today-date').innerHTML = days[date] + ", " + months[month] + " " + day + ", " + year;
})();

$('.scrollToForm').click(function(){
    $('html, body').animate({scrollTop: $('.forms').offset().top}, 'slow');
});

$('.testimonial-slider').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: true,
	arrows: false,
	autoplay: true,
	autoplaySpeed: 10000,
	adaptiveHeight: true
});
$('.cosmetic-slider').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: true,
	arrows: false,
	autoplay: true,
	autoplaySpeed: 10000,
	// adaptiveHeight: true
});
$('.benefit-cost-inside').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: true,
	arrows: false,
	autoplay: true,
	autoplaySpeed: 10000,
	adaptiveHeight: true
});

$("#people-viewing").slideUp(500).delay(7000).slideDown(500);
$("#people-viewing").slideDown(500).delay(14000).slideUp(500);

//Footer Counter
var timeleft = 62;
var index = 0;
var downloadTimer = setInterval(function(){
timeleft -= (index % 2 ? 1 : 2);
// console.log(timeleft);
index++;
document.getElementById("timer").textContent = timeleft;
if(timeleft <= 17)
	clearInterval(downloadTimer);
},8000);
