// Latest Buyer Pop
// Requires faker.js library

// Example config below must be added to page. Update values as needed.
/*
var buyerConfig = {
    packageNum: [1,3,5,7],
    imagePath: '../../../../images/257/egl5/dist/thumbs',
    imageType: '.gif',
    numberedImg: false,
    gender: 'female'
};
*/

// If gender specific needed use these list of names.
var femaleNames = [
    "Lois",
    "Mercedes",
    "Jessica",
    "Faith",
    "Andrea",
    "June",
    "Erin",
    "Vickie",
    "Alyssa",
    "Pat",
    "Selene",
    "Temeka",
    "Shawnna",
    "Kelsie",
    "Mindi",
    "Claire",
    "Dolores",
    "Meghan",
    "Linda",
    "Luana"
];

var maleNames = [
    "Danny",
    "Jordan",
    "Darren",
    "Rolando",
    "Andrew",
    "Randal",
    "Nicolas",
    "Ervin",
    "Benjamin",
    "Carl",
    "Gary",
    "Eugene",
    "Morris",
    "Shane",
    "Aaron",
    "Cedric",
    "Gerald",
    "Jonathan",
    "Oliver",
    "Vernon"
];

function chooseRandomName(gender){
    var list;

    if(gender == 'female') list = femaleNames;
    else list = maleNames;

    return list[Math.floor(Math.random() * list.length)];
}

$(document).ready(function() {
    $('.latest-buyer .item').addClass("down").removeClass("up");
    setTimeout(function(){
        upNextBuyerItem(buyerConfig)
    }, 6000);
});

function generateBuyer(buyerConfig){
    var purchaseTime = Math.floor(Math.random() * 28) + 2;
    var packageNum = buyerConfig.packageNum;
    var packageBought = buyerConfig.packageNum[Math.floor(Math.random()* buyerConfig.packageNum.length)];
    var newBuyerImage = buyerConfig.imagePath + packageBought + buyerConfig.imageType;
    var firstName = function(){
        if(buyerConfig.gender !== undefined) return chooseRandomName(buyerConfig.gender);
        else return faker.name.firstName();
    };

    $('.buyer-name').text(firstName);
    $('.buyer-location').text(faker.address.city() + ', ' + faker.address.stateAbbr());
    $('.buyer-time').text(purchaseTime);
    $('.buyer-purchase').text(packageBought);

    if(buyerConfig.numberedImg == undefined){
        $('.buyer-img').attr('src', newBuyerImage);
    }
}

function downAllBuyerItem(buyerConfig) {
    $('.latest-buyer .item').addClass("down").removeClass("up");
    setTimeout(function(){
        upNextBuyerItem(buyerConfig)
    }, 20000);
}

function upNextBuyerItem(buyerConfig) {
    generateBuyer(buyerConfig);
    $('.latest-buyer .item').addClass("up").removeClass("down");
    setTimeout(function(){
        downAllBuyerItem(buyerConfig)
    }, 4500);
}

$(".close-tog").click(function(){
    $(".latest-buyer .item").removeClass("up");
    $(".latest-buyer .item").addClass("down");
});