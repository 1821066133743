// Pricing and coupon info
var basePricing = {
	A: {
		pkg1: {
			qty: 8,
			ppuDollar: 39,
			ppuCents: 98,
			shipping: 0.00,
			subtotal: 319.84,
			retail: 639.84,
			discountP: 50,
			savings: 320,
			name1: 'buy 4 &',
			name2: 'get 4 free'
		},
		pkg2: {
			qty: 5,
			ppuDollar: 47,
			ppuCents: 98,
			shipping: 0.00,
			subtotal: 239.90,
			retail: 399.90,
			discountP: 40,
			savings: 160,
			name1: 'buy 3 &',
			name2: 'get 2 free'
		},
		pkg3: {
			qty: 3,
			ppuDollar: 66,
			ppuCents: 66,
			shipping: 0.00,
			subtotal: 199.98,
			retail: 239.94,
			discountP: 17,
			savings: 40,
			name1: 'buy 2 &',
			name2: 'get 1 50% off'
		},
		pkg4: {
			qty: 1,
			ppuDollar: 79,
			ppuCents: 98,
			shipping: 0.00,
			subtotal: 79.98,
			retail: 79.98,
			discountP: 0,
			savings: 0,
			name1: 'buy 1 set'
		}
	},
	B: {
		pkg1: {
			qty: 16,
			ppuDollar: 19,
			ppuCents: 99,
			shipping: 0.00,
			subtotal: 319.84,
			retail: 639.84,
			discountP: 50,
			savings: 320,
			name1: 'buy 4 &',
			name2: 'get 4 free'
		},
		pkg2: {
			qty: 10,
			ppuDollar: 23,
			ppuCents: 99,
			shipping: 0.00,
			subtotal: 239.90,
			retail: 399.90,
			discountP: 40,
			savings: 160,
			name1: 'buy 3 &',
			name2: 'get 2 free'
		},
		pkg3: {
			qty: 6,
			ppuDollar: 33,
			ppuCents: 33,
			shipping: 0.00,
			subtotal: 199.98,
			retail: 239.94,
			discountP: 17,
			savings: 40,
			name1: 'buy 2 &',
			name2: 'get 1 50% off'
		},
		pkg4: {
			qty: 2,
			ppuDollar: 39,
			ppuCents: 99,
			shipping: 0.00,
			subtotal: 79.98,
			retail: 79.98,
			discountP: 0,
			savings: 0,
			name1: 'buy 1 set'
		}
	},
	C: {
		pkg1: {
			qty: 7,
			ppuDollar: 49,
			ppuCents: 98,
			shipping: 0.00,
			subtotal: 349.86,
			retail: 699.86,
			discountP: 50,
			savings: 350,
			name1: 'buy 4 &',
			name2: 'get 3 free'
		},
		pkg2: {
			qty: 5,
			ppuDollar: 59,
			ppuCents: 98,
			shipping: 0.00,
			subtotal: 299.90,
			retail: 499.90,
			discountP: 40,
			savings: 200,
			name1: 'buy 3 &',
			name2: 'get 2 free'
		},
		pkg3: {
			qty: 3,
			ppuDollar: 66,
			ppuCents: 66,
			shipping: 0.00,
			subtotal: 199.98,
			retail: 299.94,
			discountP: 33,
			savings: 100,
			name1: 'buy 2 &',
			name2: 'get 1 free'
		},
		pkg4: {
			qty: 1,
			ppuDollar: 99,
			ppuCents: 98,
			shipping: 0.00,
			subtotal: 99.98,
			retail: 99.98,
			discountP: 0,
			savings: 0,
			name1: 'buy 1 set'
		}
	},
	D: {
		pkg1: {
			qty: 14,
			ppuDollar: 24,
			ppuCents: 99,
			shipping: 0.00,
			subtotal: 349.86,
			retail: 699.93,
			discountP: 50,
			savings: 350,
			name1: 'buy 4 &',
			name2: 'get 3 free'
		},
		pkg2: {
			qty: 10,
			ppuDollar: 29,
			ppuCents: 99,
			shipping: 0.00,
			subtotal: 299.90,
			retail: 499.95,
			discountP: 40,
			savings: 200,
			name1: 'buy 3 &',
			name2: 'get 2 free'
		},
		pkg3: {
			qty: 6,
			ppuDollar: 33,
			ppuCents: 33,
			shipping: 0.00,
			subtotal: 199.98,
			retail: 299.97,
			discountP: 33,
			savings: 100,
			name1: 'buy 2 &',
			name2: 'get 1 free'
		},
		pkg4: {
			qty: 1,
			ppuDollar: 49,
			ppuCents: 99,
			shipping: 0.00,
			subtotal: 99.98,
			retail: 99.99,
			discountP: 0,
			savings: 0,
			name1: 'buy 1 set'
		}
	}
};
sessionStorage.setItem('basePricing', JSON.stringify(basePricing));
